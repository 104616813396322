export const BankNames = [
    // {key: 1,name: "انتخاب کنید"},
    {key: 2,name: "آینده"},
    {key: 3,name: "دی"},
    {key: 4,name: "اقتصاد نوین"},
    {key: 5,name: "ایران زمین"},
    {key: 6,name: "کشاورزی"},
    {key: 7,name: "مسکن"},
    {key: 8,name: "مهر ایران"},
    {key: 9,name: "ملت"},
    {key: 10,name: "ملی"},
    {key: 11,name: "پارسیان"},
    {key: 12,name: "پاسارگاد"},
    {key: 13,name: "رفاه"},
    {key: 14,name: "رسالت"},
    {key: 15,name: "صادرات"},
    {key: 16,name: "سامان"},
    {key: 17,name: "سرمایه"},
    {key: 18,name: "سپه"},
    {key: 19,name: "شهر"},
    {key: 20,name: "سینا"},
    {key: 21,name: "تجارت"},
    {key: 22,name: "توسعه تعاون"},
    {key: 23,name: "کارآفرین"},
    {key: 24,name: "پست"},
    {key: 25,name: "گردشگری"},
    {key: 26,name: "موسسه ملل"},
]