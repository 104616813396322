import { Calendar as CalendarIcon, TrashIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  addBankAccountToCustomer,
  deleteBankAccountToCustomer,
  getCostomer,
  getCustomerAccountInfo,
  GetCustomerName,
  getUserInfo,
  updateCustomer,
  uploadFile
} from "../../api";
import { FileUpload } from "../../components/Customers/file-upload";
import { FormField } from "../../components/Customers/form-field";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";
import { Switch } from "../../components/ui/switch";
import { getUserSchema } from "../../lib/schemas";
import { cn } from "../../lib/utils";
import { notification, userInfo } from "../../state/action";
import { BankNames } from "../../utils/constants";
import { isNationalCodeValid } from "../../utils/helper-functions";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

import { SelectTrigger } from "@radix-ui/react-select";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { Calendar } from "react-multi-date-picker";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "../../components/ui/dialog";
import { Select, SelectContent, SelectItem } from "../../components/ui/select";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";

function EditCustomer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nationalCardImage, setNationalCardImage] = useState();
  const [nationalCardBackImage, setNationalCardBackImage] = useState();
  const [isbn, setIsbn] = useState();
  const [isForeign, setIsForeign] = useState();
  const [isFetchingIBANData, setIsFetchingIBANData] = useState(false);
  const [custometAccountInfo, setCustomerAccountInfo] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [shebaName, setShebaName] = useState("");
  const [shebaLastName, setShebaLastName] = useState("");
  const [selectedSheba, setSelectedSheba] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const nowDate = new Date();
  const [costomerId, setCostomerId] = useSearchParams();
  const [isDisabled, setIsDisabled] = useState(false);
  const [date, setDate] = useState();
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    engFname: "",
    engLname: "",
    address: "",
    zipCode: "",
    nationalCode: "",
    mobileNumber: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const schema = getUserSchema(isForeign);
    const fieldValidation = schema.shape[name].safeParse(value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldValidation.success ? undefined : fieldValidation.error.errors[0].message,
    }));
  };

  const handleFileUpload = async (file, callback) => {
    const base64 = await convertBase64(file);
    const base64_pure = base64.split(";base64,")[1];

    uploadFile(base64_pure, file.name)
      .then((response) => {
        callback(response.data.data.fileAddress);
      })
      .catch((e) => {
        dispatch(notification({ message: e.response.data.Error.Message, type: "err", id: nowDate.getSeconds() }));
      });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const handleSheba = (e) => {
    e.preventDefault();
    setIsFetchingIBANData(true)
    if (selectedBank !== "") {
      GetCustomerName(isbn)
        .then((res) => {
          setIsOpen(true);
          setShebaName(res.data.data.firstName);
          setShebaLastName(res.data.data.lastName);
        })
        .catch((err) => {
          dispatch(
            notification({
              message: err.response.data.Error.Message,
              type: "err",
              id: nowDate.getSeconds(),
            })
          );
        }).finally(() => setIsFetchingIBANData(false));
    }
  };

  const handleAddSheba = () => {
    addBankAccountToCustomer(costomerId.get("userid"), isbn, selectedBank, shebaName + " " + shebaLastName)
      .then((res) => {
        setCustomerAccountInfo((custometAccountInfo) => [...custometAccountInfo, res.data.data]);
        window.location.reload(false);
      })
      .catch((err) => {
        dispatch(
          notification({
            message: err.response.data.Error.Message,
            type: "err",
            id: nowDate.getSeconds(),
          })
        );
      });
    setIsOpen(false);
  };

  const handleDeleteSheba = (id) => {
    deleteBankAccountToCustomer(id)
      .then((res) => {
        if (res.data.isSuccess) {
          window.location.reload(false);
        }
      })
      .catch((err) => {
        dispatch(
          notification({
            message: err.response.data.Error.Message,
            type: "err",
            id: nowDate.getSeconds(),
          })
        );
      });
  };

  const handleToggleChange = () => {
    setIsForeign(!isForeign);
    setFormData({
      fname: "",
      lname: "",
      engFname: "",
      engLname: "",
      address: "",
      nationalCode: "",
      mobileNumber: "",
    });
    setErrors({});
  };

  useEffect(() => {
    getCustomerAccountInfo(costomerId.get("userid"))
      .then((res) => {
        setCustomerAccountInfo(res.data.data.list);
      })
      .catch((err) => {
        dispatch(
          notification({
            message: err.response.data.Error.Message,
            type: "err",
            id: nowDate.getSeconds(),
          })
        );
      });
  }, []);

  const validate = () => {
    const schema = getUserSchema(isForeign);
    const validation = schema.safeParse(formData);
    if (!validation.success) {
      const newErrors = {};
      validation.error.errors.forEach((error) => {
        newErrors[error.path[0]] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!validate()) return;
    if (isNationalCodeValid(formData.nationalCode)) {
      setIsDisabled(true);
      const { fname, lname, engFname, engLname, nationalCode, mobileNumber, zipCode } = formData;
      const name = `${fname}|${lname}`;
      const engName = `${engFname}|${engLname}`;

      updateCustomer(
        costomerId.get("userid"),
        name,
        engName,
        formData.address,
        zipCode,
        nationalCode,
        date,
        nationalCardImage,
        nationalCardBackImage,
        mobileNumber
      )
        .then(() => {
          dispatch(notification({ message: "اطلاعات مشتری با موفقیت ویرایش شد", type: "suc", id: nowDate.getSeconds() }));
          navigate("/customers-list");
          setIsDisabled(false);
        })
        .catch((e) => {
          dispatch(notification({ message: e.response.data?.Error?.Message, type: "err", id: nowDate.getSeconds() }));
          setIsDisabled(false);
        });
    } else {
      dispatch(notification({ message: "کد ملی معتبر نیست", type: "err", id: nowDate.getSeconds() }));
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("access_token")) {
      getUserInfo()
        .then((response) => {
          dispatch(userInfo(response.data.data));
        })
        .catch((e) => {
          navigate("/login");
        });
    } else {
      navigate("/login");
    }

    getCostomer(costomerId.get("userid"))
      .then((response) => {
        const res = response.data.data;
        setFormData((prevData) => ({
          ...prevData,
          fname: res.name.split("|")[0],
          lname: res.name.split("|")[1],
          engFname: res.engName.split("|")[0],
          engLname: res.engName.split("|")[1],
          address: res.address,
          zipCode: res.zipCode,
          nationalCode: res.nationalCode,
          mobileNumber: res.mobileNumber,
        }));
        if (res.nationalCodeExpirationDate) setDate(res.nationalCodeExpirationDate.split("T")[0].split(" ")[0]);
        setIsbn(res.isbn);
        setNationalCardImage(res.nationalCardImage);
        setNationalCardBackImage(res.nationalCardImageBack);
        setIsForeign(res.isForeign);
      })
      .catch((e) => {
        dispatch(
          notification({
            message: e.response.data.Error.Message,
            type: "err",
            id: nowDate.getSeconds(),
          })
        );
      });
  }, []);

  return (
    <div>
      <Navbar displayMenu />
      <div className="w-full flex items-start">
        <div className="hidden md:block">
          <Sidebar />
        </div>
        <div className="w-full p-8 max-md:p-4">
          <h2 className="text-xl font-bold text-gray-800 mb-12">ویرایش مشتری</h2>
          <div className="w-full border border-slate-200 max-md:border-none rounded-lg overflow-hidden p-4">
            <form onSubmit={onSubmit} className="flex flex-col items-start gap-y-4 max-w-4xl mx-auto">
              <div className="flex items-center gap-x-4 mb-4 w-full">
                <div className="max-md:col-span-1">
                  <Label className="inline-block mb-1 !mr-0" htmlFor="isForeign">
                    اتباع خارجی
                  </Label>
                </div>
                <div className="col-span-5">
                  <Switch className="!m-0" id="isForeign" onCheckedChange={handleToggleChange} pressed={isForeign} />
                </div>
              </div>

              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <FormField
                  id="fname"
                  label="نام"
                  name="fname"
                  value={formData.fname}
                  onChange={handleInputChange}
                  error={errors.fname}
                  description="لطفاً نام کاربر را به فارسی وارد کنید"
                  required
                />

                <FormField
                  id="lname"
                  label="نام خانوادگی"
                  name="lname"
                  value={formData.lname}
                  onChange={handleInputChange}
                  error={errors.lname}
                  description="لطفاً نام خانوادگی کاربر را به فارسی وارد کنید"
                  required
                />
              </div>

              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <FormField
                  id="engFname"
                  label="نام انگلیسی"
                  name="engFname"
                  value={formData.engFname}
                  onChange={handleInputChange}
                  error={errors.engFname}
                  description="لطفاً نام کاربر را به انگلیسی وارد کنید"
                  required
                />

                <FormField
                  id="engLname"
                  label="نام خانوادگی انگلیسی"
                  name="engLname"
                  value={formData.engLname}
                  onChange={handleInputChange}
                  error={errors.engLname}
                  description="لطفاً نام خانوادگی کاربر را به انگلیسی وارد کنید"
                  required
                />
              </div>
              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <FormField
                  id="mobileNumber"
                  dir="auto"
                  inputMode="tel"
                  label="شماره موبایل"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  error={errors.mobileNumber}
                  description="لطفاً شماره موبایل کاربر را وارد کنید"
                  required
                />
                <FormField
                  id="zipCode"
                  dir="auto"
                  inputMode="numeric"
                  label="کدپستی"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  error={errors.zipCode}
                  description="کد پستی کاربر را وارد کنید"
                  required
                />
              </div>
              <FormField
                id="address"
                label="آدرس"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                error={errors.address}
                description="آدرس محل سکونت کاربر را وارد کنید"
                textarea
                required={isForeign}
              />
              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <FileUpload
                  id="id-card-front"
                  label={isForeign ? "تصویر کارت آمایش یا گذرنامه" : "تصویر کارت ملی"}
                  name="id-card-front"
                  onFileUpload={(file) => handleFileUpload(file, setNationalCardImage)}
                  uploadedImage={nationalCardImage}
                  description={isForeign ? "تصویر کارت آمایش یا گذرنامه کاربر را آپلود کنید" : "تصویر کارت ملی کاربر را آپلود کنید"}
                />

                <FileUpload
                  id="id-card-back"
                  label={isForeign ? "تصویر ویزا" : "تصویر پشت کارت ملی"}
                  name="id-card-back"
                  onFileUpload={(file) => handleFileUpload(file, setNationalCardBackImage)}
                  uploadedImage={nationalCardBackImage}
                  description={isForeign ? "تصویر ویزای کاربر را آپلود کنید" : "تصویر پشت کارت ملی کاربر را آپلود کنید"}
                />
              </div>

              <div className={cn(isForeign ? "w-1/2" : "w-full", "flex max-md:flex-col items-start gap-x-12")}>
                {!isForeign && (
                  <div className="grid grid-cols-1 gap-4 mb-4 w-full">
                    <div className="flex items-center justify-between col-span-3">
                      <Label className="inline-block mb-1 !mr-0" htmlFor="nationalCodeExpirationDate">
                        تاریخ انقضا کارت ملی
                      </Label>
                    </div>
                    <div className="col-span-3">
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "[&>span]:text-gray-800 !m-0 w-full justify-start text-left whitespace-normal font-normal",
                              !date && "text-muted-foreground"
                            )}
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {date ? date : <span>انتخاب</span>}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0 [& span]:!text-gray-800">
                          <Calendar
                            onChange={(e) => setDate(e.format("YYYY/MM/DD").replace(/[۰-۹]/g, d => d.charCodeAt(0) - 1776))}
                            name="nationalCodeExpirationDate"
                            calendar={persian}
                            locale={persian_fa}
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                )}
                <FormField
                  id="nationalCode"
                  dir="auto"
                  inputMode="numeric"
                  label={isForeign ? "شماره فراگیر" : "شماره ملّی"}
                  name="nationalCode"
                  value={formData.nationalCode}
                  onChange={handleInputChange}
                  error={errors.nationalCode}
                  description={isForeign ? "شماره فراگیر کاربر را وارد کنید" : "کد ملّی کاربر را وارد کنید"}
                  required={isForeign ? false : true}
                />
              </div>

              <Button className="!m-0" type="submit" disabled={isDisabled}>
                ثبت اطلاعات
              </Button>
            </form>
            <form className="w-full mt-16 flex flex-col items-start gap-y-4 max-w-4xl mx-auto" onSubmit={handleSheba}>
              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <FormField disabled={isFetchingIBANData} placeholder='شماره شبا' label="لیست اطلاعات بانکی مشتری" value={isbn} onChange={e => setIsbn(e.target.value)} error={errors.nationalCode} required />
                <div className="grid grid-cols-1 gap-4 mb-4 w-full">
                  <div className="flex items-center justify-between col-span-3">
                    <Label className="inline-block mb-1 !mr-0" >
                      نام بانک
                    </Label>
                  </div>
                  <div className="flex gap-4 col-span-3">
                    <Select disabled={isFetchingIBANData} onValueChange={(value) => setSelectedBank(value)}>
                      <SelectTrigger asChild>
                        <Button variant="outline" className="w-full !m-0">
                          {selectedBank || 'نام بانک'}
                        </Button>
                      </SelectTrigger>
                      <SelectContent>
                        {BankNames.map((item, index) => (
                          <SelectItem className='[&>span]:!text-gray-800' key={item.key} value={item.name}>
                            {item.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Button type="submit" className='!m-0' disabled={!isbn || isFetchingIBANData}>
                      {isFetchingIBANData ? 'در حال بررسی' : 'افزودن'}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            <div className="w-full max-w-4xl mx-auto mt-4">
              <Table>
                <TableCaption>لیست اطلاعات بانکی مشتری</TableCaption>
                <TableHeader>
                  <TableRow>
                    <TableHead className='!bg-slate-200 text-center'>نام بانک</TableHead>
                    <TableHead className='!bg-slate-200 text-center'>شماره شبا</TableHead>
                    <TableHead className='!bg-slate-200 text-center'> </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {custometAccountInfo.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell className='!bg-transparent'>{item.bankName} </TableCell>
                      <TableCell className='!bg-transparent'>{item.sheba}</TableCell>
                      <TableCell className='!bg-transparent'>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setIsDeleteOpen(true);
                            setSelectedSheba(item.id);
                          }}
                        >
                          <TrashIcon className="w-4 h-4 text-red-500" />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={isOpen} onOpenChange={() => setIsOpen(prev => !prev)}>
        <DialogContent className='text-center'>
          <p className="mt-4">{`شماره شبای ${isbn}`}</p>
          <p>{`متعلق به ${shebaName} ${shebaLastName} میباشد`}</p>
          <DialogFooter>
            <Button className='!m-0 !px-4 !me-2' onClick={() => handleAddSheba(selectedSheba)} type='button'>تایید</Button>
            <Button className='!m-0 !px-4' onClick={() => setIsOpen(false)} variant='outline' type='button'>انصراف</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteOpen} onOpenChange={() => setIsDeleteOpen(prev => !prev)}>
        <DialogContent className='text-center'>
          <DialogHeader className='text-center my-4 sm:text-center'>شماره شبای مورد نظر حذف شود؟</DialogHeader>
          <DialogFooter>
            <Button className='!m-0 !px-4 !me-2' onClick={() => handleDeleteSheba(selectedSheba)} type='button'>تایید</Button>
            <Button className='!m-0 !px-4' onClick={() => setIsDeleteOpen(false)} variant='outline' type='button'>انصراف</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditCustomer;
