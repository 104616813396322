import React from 'react'
import styles from './style.module.scss';
import Input from "../../components/Input";
import Button from "../../components/Button";
import Message from "../../components/Message";
import { useSelector, useDispatch } from 'react-redux';
import { getUserInfo, addBank } from '../../api';
import { useNavigate } from 'react-router-dom';
import { userInfo } from '../../state/action';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { notification } from '../../state/action';
import usePostAdminBankAccount from '../../hooks/admin-local-bank-account'

function AddAdminLocalBankAccount() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formValues, setFormValues] = React.useState({ iban: '' })
    const usePostAdminBankAccountMutation = usePostAdminBankAccount.usePostAdminBankAccount()

    React.useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }
    }, [])

    const onSubmit = async (event) => {
        event.preventDefault()
        if (Object.values(formValues).filter(item => item === '').length > 0) return dispatch(notification({ message: "اطلاعات فرم صحیح نمی باشد", type: "err", id: new Date().getSeconds() }))
        const res = await usePostAdminBankAccountMutation.mutateAsync(formValues);
        if (res.isSuccess)
            navigate('/local-bank-account-list');
    }

    return (
        <div>
            <Navbar />
            <Sidebar />
            <div className={styles.left_side}>
                <div className={styles.Profile_form}>
                    <div className={styles.form_header}>افزودن شماره حساب شرکت</div>

                    <form className={styles.form_items} onSubmit={onSubmit}>
                        <div className={styles.form_item} dir='ltr'>
                            <Input
                                placeholder="شماره شبا"
                                type="text"
                                value={formValues.iban}
                                onChange={(e) => setFormValues(prev => ({ ...prev, iban: e }))}
                                required="true"
                            />
                        </div>

                        <Button text="ثبت اطلاعات" />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddAdminLocalBankAccount;