import styles from './style.module.scss';
import React from "react";
import { useDispatch } from 'react-redux';
import { getUserInfo } from '../../api';
import { useNavigate } from 'react-router-dom';
import { userInfo } from '../../state/action';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faExclamationTriangle } from '@fortawesome/fontawesome-free-solid'
import Button from "../../components/Button";

import slideImg1 from '../../assets/image/loading.gif';

import { useAdminLocalBankAccount } from '../../hooks'


function AdminLocalBankAccount() {
    const baseURL = process.env.REACT_APP_BASE_FILE_URL;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pagination, setPagination] = React.useState({ page: 0, pageSize: 10 })

    const getAdminBankAccountQuery = useAdminLocalBankAccount.useGetAdminBankAccount({ ...pagination })
    const useDeleteAdminBankAccountMutation = useAdminLocalBankAccount.useDeleteAdminBankAccount()

    React.useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }
        // getList(1, pageSize)
    }, [])
    const onDeleteAccount = (row) => {
        useDeleteAdminBankAccountMutation.mutateAsync({ id: row.id })
    }

    return (
        <div>
            <Navbar />
            <Sidebar />
            <div className={styles.left_side}>
                <div className={styles.search_add}>
                    <div className={styles.add_btn} onClick={() => navigate("/local-bank-account-add-new")}><FontAwesomeIcon icon={faUserPlus} /> افزودن حساب جدید</div>
                </div>
                {getAdminBankAccountQuery.data?.list?.length > 0
                    ? (
                        <div className={styles.customer_list}>
                            <div className={styles.list_header}>لیست حساب های شرکت
                                <select value={pagination.pageSize} onChange={(e) => setPagination(prev => ({ ...prev, pageSize: e.target.value }))} className={styles.page_size} id="itemPPage">
                                    <option disabled value="">تعداد آیتم‌ها</option>
                                    <option value={1}>1 ردیف</option>
                                    <option value={5}>5 ردیف</option>
                                    <option value={10}>10 ردیف</option>
                                    <option value={15}>15 ردیف</option>
                                    <option value={20}>20 ردیف</option>
                                </select>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>ردیف</th>
                                        <th>ایکون بانک</th>
                                        <th>نام بانک</th>
                                        <th>شماره شبا</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getAdminBankAccountQuery.data?.list?.map((acc, item) => (
                                        <tr key={acc.id}>
                                            <td>{item + 1}</td>
                                            <td><img src={baseURL + '/' + acc.bankIconFile} className={styles.bank_img} alt=""/></td>
                                            <td>{acc.bankName}</td>
                                            <td>{acc.iban}</td>
                                            <td><Button text="حذف" onClick={() => onDeleteAccount(acc)} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {getAdminBankAccountQuery.isFetching === true ? <img className={styles.loading_gif} src={slideImg1} alt="" /> : ""}
                        </div>
                    )
                    :
                    <div className={styles.empty_alert}>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <span>موردی یافت نشد</span>
                    </div>
                }
            </div>
        </div>
    );
};

export default AdminLocalBankAccount;