import { flexRender } from "@tanstack/react-table";
import React from "react";
import { Checkbox } from "../../../components/ui/checkbox";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";

interface DataTableProps {
  table: any;
  expandedRows: any;
  ibanSelection: { [key: string]: string };
  onIbanCheck: any;
}

const DataTable: React.FC<DataTableProps> = ({ table, ibanSelection, expandedRows, onIbanCheck }) => {

  const handleIbanCheck = (rowId: string, iban: string) => {
    onIbanCheck((prev: any) => {
      if (prev[rowId] === iban) {
        const { [rowId]: _, ...rest } = prev;
        return rest;
      } else {
        return {
          ...prev,
          [rowId]: iban,
        };
      }
    });
  };

  return (
    <div>
      <div style={{ maxHeight: '60vh' }}>
        <Table>
          <TableHeader className="!bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <TableHead className="py-0.5 text-center"></TableHead>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className="py-0.5 text-center">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {table.getRowModel().rows.map((row, index) => {
              const rowClassName = index % 2 === 0 ? "bg-blue-50" : "bg-white";
              const isExpanded = expandedRows[row.original.id];

              return (
                <React.Fragment key={row.id}>
                  <TableRow className={rowClassName}>
                    <TableCell className="!bg-transparent py-0.5 max-md:text-xs [&:has([role=checkbox])]:pr-4">
                      <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={(value) => row.toggleSelected(!!value)}
                        aria-label="Select row"
                      />
                    </TableCell>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell className="!bg-transparent py-0.5 max-md:text-xs" key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>

                  {isExpanded && (
                    <TableRow>
                      <TableCell colSpan={(table.getAllColumns()?.length || 0) + 1} className="p-0 bg-gray-50">
                        {row.original.bankAccountList?.length > 0 ? (
                          <div className="space-y-2">
                            {row.original.bankAccountList.map((account) => (
                              <div key={account.id} className="flex items-center gap-x-2">
                                <Checkbox
                                  checked={ibanSelection[row.original.id] === account.shebaNumber}
                                  onCheckedChange={() => handleIbanCheck(row.original.id, account.shebaNumber)}
                                  aria-label="Select IBAN"
                                />
                                <div className="font-medium">{account.bankName}</div>
                                <div className="text-gray-600">{account.shebaNumber}</div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="text-gray-500">No bank accounts available.</div>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default DataTable;
