import { cn } from "../../lib/utils";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";

export function FormField({ id, label, name, value, onChange, error, type = "text", textarea = false, description = "", ...props }) {
  return (
    <div className="grid grid-cols-1 gap-4 mb-4 w-full">
      <div className="flex items-center justify-between col-span-3">
        <Label className="inline-block mb-1 !mr-0" htmlFor={id}>
          {label}
          {props.required ? <span className="text-red-500">*</span> : null}
        </Label>
        {error && <p className="text-red-500 text-xs mt-1 text-left">{error}</p>}
      </div>
      <div className="col-span-3">
        {textarea ? (
          <Textarea
            rows={5}
            name={name}
            value={value}
            onChange={onChange}
            className={cn("w-full", error ? "border-red-500" : "")}
            {...props}
          />
        ) : (
          <Input
            id={id}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            className={cn("w-full", error ? "border-red-500" : "")}
            {...props}
          />
        )}
      </div>
    </div>
  );
}