import { MenuIcon } from "lucide-react";
import { useSelector } from "react-redux";
import ProfileIcon from "../../components/ProfileIcon";
import { Sheet, SheetContent, SheetTrigger } from "../../components/ui/sheet";
import { cn } from "../../lib/utils";
import Sidebar from "../Sidebar";
import styles from "./style.module.scss";

function Navbar({ displayMenu = false }) {
  const userInfo = useSelector((store) => store.userInfo);

  return (
    <div className={cn(styles.navbar, 'flex items-center px-2')}>
      {userInfo && (
        <ProfileIcon name={userInfo.name} userName={userInfo.userName} />
      )}
      <div className={styles.navbar_title}>سامانه نوآپی</div>
      {displayMenu ? (
        <Sheet>
          <SheetTrigger className="block md:hidden" asChild>
            <MenuIcon className="w-6 h-6 shrink-0 text-white" />
          </SheetTrigger>
          <SheetContent className="w-max bg-white pt-10">
            <Sidebar />
          </SheetContent>
        </Sheet>
      ) : null}
    </div>
  );
}

export default Navbar;
