import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteCustomer, getCostomerList, getCostomerListForExport, getCustomerAccountInfo, getUserInfo } from "../../api";
import { notification, userInfo } from "../../state/action";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

import { columns } from "./components/columns";
import { DataTable } from "./components/data-table";
import TableToolbar from "./components/table-toolbar";

import AccountsListModal from "./components/accounts-list-modal";
import { exportExcel } from "./utils/export-excel.ts";
import { exportCSV } from "./utils/export-csv.ts";

function CustomerList() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [customerNationalCode, setCustomerNationalCode] = useState("");
  const [customerFirstFaName, setCustomerFirstFaName] = useState("");
  const [customerLastFaName, setCustomerLastFaName] = useState("");
  const [customerFirstEngName, setCustomerFirstEngName] = useState("");
  const [customerLastEngName, setCustomerLastEngName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isForeigner, setIsForeigner] = useState(false);
  const [hasNationalCardImage, setHasNationalCardImage] = useState("2");
  const [displayAccount, setDisplayAccounts] = useState(false);
  const [accountsList, setAccountsList] = useState([]);

  const nowDate = new Date();

  const getList = (
    currentPage = page,
    pageSizeI = pageSize,
    customerFirstFaName = "",
    customerLastFaName = "",
    customerFirstEngName = "",
    customerLastEngName = "",
    customerNationalCode = "",
    mobileNumber = "",
    isForeigner = "",
    hasNationalCardImage = "2"
  ) => {
    setLoading(true);
    getCostomerList(
      customerFirstFaName,
      customerLastFaName,
      customerFirstEngName,
      customerLastEngName,
      customerNationalCode,
      mobileNumber,
      isForeigner,
      hasNationalCardImage === "1" ? true : hasNationalCardImage === "0" ? false : null,
      currentPage,
      pageSizeI
    )
      .then((response) => {
        setLoading(false);
        setCustomers(response.data.data.list);
        setPage(response.data.data.page);
        setTotalPage(response.data.data.totalPage);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const updateURLParams = (pageValue = page, pageSizeValue = pageSize) => {
    const params = new URLSearchParams({
      customerNationalCode,
      customerFirstFaName,
      customerLastFaName,
      customerFirstEngName,
      customerLastEngName,
      mobileNumber,
      isForeigner,
      hasNationalCardImage,
      page: pageValue.toString(),
      pageSize: pageSizeValue.toString(),
    });
    navigate(`?${params.toString()}`, { replace: true });
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
    updateURLParams(1, value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    updateURLParams(newPage, pageSize);
  };

  const addNewCustomer = () => {
    navigate("/add-new-customer");
  };

  const editUser = (userId) => {
    navigate("/edit-customer?userid=" + userId);
  };

  const deleteCustomer1 = (id) => {
    if (window.confirm("مشتری حذف شود؟")) {
      deleteCustomer(id)
        .then((response) => {
          dispatch(
            notification({
              message: "مشتری با موفقیت حذف شد",
              type: "suc",
              id: nowDate.getSeconds(),
            })
          );
          getList(1, pageSize);
        })
        .catch((e) => {
          dispatch(
            notification({
              message: e.response.data.Error.Message,
              type: "err",
              id: nowDate.getSeconds(),
            })
          );
        });
    }
  };

  const handleSubmitFilters = (e) => {
    e.preventDefault();
    setPage(1);
    updateURLParams(1, pageSize);
  };

  const removeFilter = (filterKey) => {
    const params = new URLSearchParams(location.search);
    params.delete(filterKey);

    switch (filterKey) {
      case "customerNationalCode":
        setCustomerNationalCode("");
        break;
      case "customerFirstFaName":
        setCustomerFirstFaName("");
        break;
      case "customerLastFaName":
        setCustomerLastFaName("");
        break;
      case "customerFirstEngName":
        setCustomerFirstEngName("");
        break;
      case "customerLastEngName":
        setCustomerLastEngName("");
        break;
      case "mobileNumber":
        setMobileNumber("");
        break;
      case "isForeigner":
        setIsForeigner("");
        break;
      case "hasNationalCardImage":
        setHasNationalCardImage("2");
        break;
      default:
        break;
    }

    navigate(`?${params.toString()}`, { replace: true });
  };

  const handleGetCustomerListInfo = (id) => {
    setDisplayAccounts(true);
    getCustomerAccountInfo(id).then((res) => {
      setAccountsList(res.data.data.list);
    });
  };

  const handleToggleDisplayAccounts = () => {
    setDisplayAccounts((prev) => !prev);
  };

  const handleCustomersExport = async (type) => {
    const response = await getCostomerListForExport();
    if (!response.data.isSuccess) {
      dispatch(
        notification({
          message: response.data.message,
          type: "err",
          id: nowDate.getSeconds(),
        })
      );
      return;
    }
    if (type === "xlsx") {
      exportExcel(response.data.data.list);
    } else {
      exportCSV(response.data.data.list);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const customerNationalCodeFromParams = params.get("customerNationalCode") || "";
    const customerFirstFaNameFromParams = params.get("customerFirstFaName") || "";
    const customerLastFaNameFromParams = params.get("customerLastFaName") || "";
    const customerFirstEngNameFromParams = params.get("customerFirstEngName") || "";
    const customerLastEngNameFromParams = params.get("customerLastEngName") || "";
    const mobileNumber = params.get("mobileNumber") || "";
    const isForeigner = params.get("isForeigner") || "";
    const hasNationalCardImage = params.get("hasNationalCardImage") || "2";
    const pageFromParams = parseInt(params.get("page")) || 1;
    const pageSizeFromParams = parseInt(params.get("pageSize")) || 10;

    setCustomerNationalCode(customerNationalCodeFromParams);
    setCustomerFirstFaName(customerFirstFaNameFromParams);
    setCustomerLastFaName(customerLastFaNameFromParams);
    setCustomerFirstEngName(customerFirstEngNameFromParams);
    setCustomerLastEngName(customerLastEngNameFromParams);
    setMobileNumber(mobileNumber);
    setIsForeigner(isForeigner === "true" ? true : isForeigner === "true" ? false : "");
    setHasNationalCardImage(hasNationalCardImage);
    setPage(pageFromParams);
    setPageSize(pageSizeFromParams);

    getList(
      pageFromParams,
      pageSizeFromParams,
      customerFirstFaNameFromParams,
      customerLastFaNameFromParams,
      customerFirstEngNameFromParams,
      customerLastEngNameFromParams,
      customerNationalCodeFromParams,
      mobileNumber,
      isForeigner,
      hasNationalCardImage,
    );
  }, [location.search]);

  useEffect(() => {
    if (sessionStorage.getItem("access_token")) {
      getUserInfo()
        .then((response) => {
          dispatch(userInfo(response.data.data));
        })
        .catch((e) => {
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      <Navbar displayMenu />
      <div className="w-full flex items-start">
        <div className="hidden md:block">
          <Sidebar />
        </div>
        <div className="w-full p-8 max-md:p-4 overflow-x-auto">
          <h2 className="text-xl font-bold text-gray-800 mb-12">فهرست مشتریان</h2>
          <div className="w-full bg-slate-100 border border-slate-200 rounded-lg overflow-hidden">
            <TableToolbar
              onRemoveFilter={removeFilter}
              onAddNewCustomer={addNewCustomer}
              onSearch={handleSubmitFilters}
              customerNationalCode={customerNationalCode}
              setCustomerNationalCode={setCustomerNationalCode}
              setCustomerFirstFaName={setCustomerFirstFaName}
              setCustomerLastFaName={setCustomerLastFaName}
              setCustomerFirstEngName={setCustomerFirstEngName}
              setCustomerLastEngName={setCustomerLastEngName}
              mobileNumber={mobileNumber}
              setMobileNumber={setMobileNumber}
              isForeigner={isForeigner}
              setIsForeigner={setIsForeigner}
              hasNationalCardImage={hasNationalCardImage}
              setHasNationalCardImage={setHasNationalCardImage}
              onCustomersExport={handleCustomersExport}
            />

            <div className="rounded-lg overflow-hidden border-t border-gray-300">
              <DataTable
                columns={columns}
                data={customers}
                page={page}
                pageSize={pageSize}
                totalPage={totalPage}
                onPageChange={handlePageChange}
                loading={loading}
                onEditUser={editUser}
                onDeleteUser={deleteCustomer1}
                onPageSizeChange={handlePageSizeChange}
                onGetCustomerListInfo={handleGetCustomerListInfo}
              />
            </div>
          </div>
        </div>
      </div>
      <AccountsListModal accounts={accountsList} displayAccount={displayAccount} onToggleDisplayAccounts={handleToggleDisplayAccounts} />
    </div>
  );
}

export default CustomerList;
