
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { cn } from "../../../lib/utils";

function AccountsListModal({
  displayAccount,
  accounts,
  onToggleDisplayAccounts,
}) {
  return (
    <Dialog
      dir="rtl"
      open={displayAccount}
      onOpenChange={onToggleDisplayAccounts}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-center">
            فهرست حساب‌های {accounts[0]?.customerName || "کاربر"}
          </DialogTitle>
          <Table>
            <TableHeader className="!bg-white">
              <TableRow>
                <TableHead className="w-[100px] py-0.5 [&>button]:!m-0 [&>button]:max-md:text-xs text-center">
                  نام بانک
                </TableHead>
                <TableHead className="py-0.5 [&>button]:!m-0 [&>button]:max-md:text-xs text-center">
                  شماره شبا
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {accounts.map((account, index) => (
                <TableRow
                  className={cn(index % 2 === 0 ? "bg-blue-50" : "bg-white")}
                  key={account.id}
                >
                  <TableCell className="font-medium !bg-transparent py-2 max-md:text-xs">
                    {account.bankName}
                  </TableCell>
                  <TableCell className="!bg-transparent py-2 max-md:text-xs">
                    {account.sheba}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export default AccountsListModal;
