import { MoreHorizontal } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Skeleton } from "../../../components/ui/skeleton";
import { TableCell, TableRow } from "../../../components/ui/table";

function TableSkeleton({count = 5}) {
  return (
    <>
      
      {Array.from({length: count}).map(_ => (<TableRow>
        <TableCell className="bg-white" colSpan={5}>
          <Skeleton className="w-full h-8 rounded-lg" />
        </TableCell>
        <TableCell className="bg-white">
          <Button variant="ghost" className="h-8 w-8 p-0">
            <MoreHorizontal className="!m-0 h-4 w-4" />
          </Button>
        </TableCell>
      </TableRow>))}
    </>
  );
}

export default TableSkeleton;
